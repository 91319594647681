// 合同状态枚举
export const CONTRACT_STATUS = {
  FAIL: 0,
  AUDIT_PENDING: 1,
  EXEC_PENDING: 2,
  EXECUTING: 3,
  FINISH: 4,
};

export const CONTRACT_STATUS_OPTIONS = [
  { value: CONTRACT_STATUS.FAIL, label: '审核失败' },
  { value: CONTRACT_STATUS.AUDIT_PENDING, label: '待审核' },
  { value: CONTRACT_STATUS.EXEC_PENDING, label: '待履行' },
  { value: CONTRACT_STATUS.EXECUTING, label: '审核成功' },
  { value: CONTRACT_STATUS.FINISH, label: '已完成' },
];

// 合同类型枚举
export const CONTRACT_TYPES = {
  MAIN: 0,
  SUB: 1,
  SUPPLEMENT: 2,
};

export const CONTRACT_TYPE_OPTIONS = [
  { value: CONTRACT_TYPES.MAIN, label: '主合同' },
  { value: CONTRACT_TYPES.SUB, label: '子合同' },
  { value: CONTRACT_TYPES.SUPPLEMENT, label: '补充合同' },
];

// 合同收付类型枚举
export const CONTRACT_PAYMENT_TYPES = {
  PAYMENT: 0,
  RECEIPT: 1,
};

// 通知的优先级对应的TAG类型
export const NOTIFICATION_TYPES = {
  low: 'primary',
  middle: 'warning',
  high: 'danger',
};

// 通用请求头
export enum ContentTypeEnum {
  Json = 'application/json;charset=UTF-8',
  FormURLEncoded = 'application/x-www-form-urlencoded;charset=UTF-8',
  FormData = 'multipart/form-data;charset=UTF-8',
}

// ====================================

// 作品状态枚举
export const CREATIONS_STATUS = {
  auditPending: '待审核',
  auditRejected: '驳回',
  onTheShelf: '上架',
  offTheShelf: '下架',
};

// 登录类型
export type LoginType = 'login' | 'register';

export type BillingSetupType = 'Create' | 'Edit';

export enum BillingSetupTypeTitle {
  Create = '新建开票信息',
  Edit = '编辑开票信息',
}

// 入驻类型
export const SETTLED_TYPE = {
  PRODUCT_COMPANY: '商品供应商（企业）',
  PRODUCT_PERSONAL: '商品供应商（个体）',
  SERVISE_COMPANY: '服务供应商（企业）',
  SERVISE_PERSONAL: '服务供应商（个体）',
};

// 供货申请类型
export type SupplyRequestType = 'bid' | 'apply';

export enum SupplyRequestTypeTitle {
  bid = '竞价',
  supply = '申请',
}

export enum SupplyRequestButtonText {
  bidding = '竞价供货',
  apply = '申请供货',
}

export const ORDER_STATUS_TEXT_ENUM: Record<string, string> = {
  // pending_payment: '待付款',
  pending_shipment: '待发货',
  shipped: '已发货',
  canceled: '已取消',
  completed: '已完成',
};

export const ORDER_STATUS_TAG_ENUM: Record<string, string> = {
  pending_payment: 'primary',
  pending_shipment: 'warning',
  shipped: 'warning',
  canceled: 'danger',
  completed: 'success',
};

// 订单来源；product_center=来萃好物，works=从作品下单；user_share=用户分享;live=直播带货
export const ORDER_FROM_ENUM = {
  product_center: '来萃好物',
  works: '从作品下单',
  user_share: '用户分享',
  live: '直播带货',
};

// 下单入口； android-mini-program=安卓小程序；ios-mini-program=苹果小程序;android-app=安卓app;ios-app=苹果app
export const ORDER_ENTRY_ENUM = {
  'android-miniprogram': '安卓小程序',
  'ios-miniprogram': '苹果小程序',
  'android-app': '安卓客户端',
  'ios-app': '苹果客户端',
};

// 订单类型：product=普通商品；service=服务商品
export const ORDER_TYPE_ENUM = {
  product: '普通商品',
  service: '服务商品',
};

// 销售类型：platform=平台商品，personal=私域商品
export const ORDER_SALES_TYPE_ENUM = {
  platform: '平台商品',
  personal: '私域商品',
};

// 配送方式 merchant=商家配送，warehouse=仓库配送
export const ORDER_DELIVERY_TYPE_ENUM = {
  merchant: '商家配送',
  warehouse: '仓库配送',
};

// "售后状态 退款状态；wait=已申请待审核；reject=退款被拒绝；finish=退款成功"
export const ORDER_AFTER_SALES_STATUS_ENUM = {
  processing: '处理中',
  finish: '已完成',
  cancel: '已取消',
};

// 支付方式，wechat_pay=微信支付;alipay=支付宝支付;
export const ORDER_PAYMENT_TYPE_ENUM = {
  wechat_pay: '微信支付',
  alipay: '支付宝支付',
};

//  '积分，余额抵扣方式；use_balance=使用积分，use_points=使用余额;',
export const ORDER_DISCOUNT_TYPE_ENUM = {
  use_balance: '余额',
  use_points: '积分',
};

// 订单收货地址审核状态 wait=待审核，reject=驳回，pass=通过
export const ORDER_ADDRESS_AUDIT_STATUS_TEXT_ENUM = {
  wait: '待审核',
  reject: '驳回',
  pass: '通过',
};

export const ORDER_ADDRESS_AUDIT_STATUS_THEME_ENUM = {
  wait: 'warning',
  reject: 'danger',
  pass: 'primary',
};

export const RECONCILIATION_STATUS = {
  wait: '对账中',
  pass: '已对账',
};
