import { defineStore } from 'pinia';

import type { LoginByPhoneParams } from '@/api/model/userModel';
import { getUserInfo, loginByPhone } from '@/api/user';
import { TOKEN_NAME } from '@/config/global';
import { usePermissionStore } from '@/store';
import type { UserInfo } from '@/types/interface';

const InitUserInfo: UserInfo = {
  account: '',
  avatar: '',
  permissionList: [],
  status: '',
  supplierId: undefined,
  supplierName: '',
  suppliersStatus: '',
  suppliersAuditStatus: '',
  auditRemark: '',
  username: '',
  storeInformationId: null,
  settledType: '',
  userId: '',
};

export const useUserStore = defineStore('user', {
  state: () => ({
    token: localStorage.getItem(TOKEN_NAME) || '',
    userInfo: { ...InitUserInfo }, // 当前登录用户信息
  }),
  getters: {
    permissionList: (state) => {
      return state.userInfo?.permissionList;
    },
  },
  actions: {
    async login(loginForm: LoginByPhoneParams) {
      const res = await loginByPhone(loginForm);
      this.token = res.data;
      localStorage.setItem(TOKEN_NAME, res.data);
    },
    loginByQRCode(token: string) {
      this.token = token;
      localStorage.setItem(TOKEN_NAME, token);
    },
    async getUserInfo() {
      const res = await getUserInfo();
      this.userInfo = res.data;
      return res.data;
    },
    async logout() {
      this.token = '';
      this.userInfo = { ...InitUserInfo };
      localStorage.removeItem(TOKEN_NAME);
    },
  },
  persist: {
    afterRestore: () => {
      const permissionStore = usePermissionStore();
      permissionStore.initRoutes();
    },
  },
});
