<template>
  <div>
    <t-popup
      v-model="visiblePopup"
      expand-animation
      placement="bottom-right"
      trigger="click"
      @visible-change="onVisibleChange"
    >
      <template #content>
        <div class="header-msg">
          <div class="header-msg-top">
            <p>通知中心</p>
            <t-button
              v-if="unreadMsg.length > 0"
              class="clear-btn"
              variant="text"
              theme="primary"
              @click="emptyUnreadMsg"
              >清空</t-button
            >
          </div>
          <t-list v-if="unreadMsg.length > 0" class="narrow-scrollbar" :split="false">
            <t-list-item v-for="(item, index) in unreadMsg" :key="index" @click="(event) => openDetials(event, item)">
              <div>
                <p class="msg-content">{{ item.content }}</p>
                <p class="msg-type">{{ item.title }}</p>
              </div>
              <p class="msg-time">{{ item.createTime }}</p>
              <template #action>
                <t-button size="small" variant="outline" @click.stop="handleRead(item)"> 设为已读 </t-button>
              </template>
            </t-list-item>
          </t-list>

          <div v-else class="empty-list">
            <img src="https://tdesign.gtimg.com/pro-template/personal/nothing.png" alt="空" />
            <p>暂无通知</p>
          </div>
          <div class="header-msg-bottom">
            <t-button class="header-msg-bottom-link" variant="text" theme="default" block @click="goDetail"
              >查看全部</t-button
            >
          </div>
        </div>
      </template>
      <t-badge :count="notificationStore.unreadNum" :offset="[4, 4]">
        <t-button theme="default" shape="square" variant="text">
          <t-icon name="mail" />
        </t-button>
      </t-badge>
    </t-popup>
    <open-details-by-notifiy-data ref="openDetailsByNotifiyDataRef" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { allMarkRead, GetNotifiyMsgList, setMsgReadById } from '@/api/globalNotification/index';
import type { IGetNotifiyMsgListItem } from '@/api/model/globalNotificationModel';
import OpenDetailsByNotifiyData from '@/pages/systemSettings/messageCenter/components/OpenDetailsByNotifiyData.vue';
import { useNotificationStore } from '@/store';

const notificationStore = useNotificationStore();
const visiblePopup = ref(false);

const unreadMsg = ref<IGetNotifiyMsgListItem[]>([]);

const router = useRouter();

const goDetail = () => {
  router.push('/system-settings/message-center');
};
const onVisibleChange = async (value: boolean) => {
  if (!value) return;
  getNotifiyMsgListRequest();
};

const getNotifiyMsgListRequest = async () => {
  const result = await GetNotifiyMsgList({
    subjectType: null,
    current: 1,
    pageSize: 4,
    readFlag: 0,
  });
  unreadMsg.value = result.data.records;
};

const handleRead = async (item: IGetNotifiyMsgListItem) => {
  await setMsgReadById(item.msgId);
  const index = unreadMsg.value.findIndex((v) => v.msgId === item.msgId);
  if (index !== -1) {
    unreadMsg.value.splice(index, 1);
  }
};

const emptyUnreadMsg = async () => {
  await allMarkRead({ subjectType: 'all' });
  getNotifiyMsgListRequest();
};

const openDetailsByNotifiyDataRef = ref<InstanceType<typeof OpenDetailsByNotifiyData> | null>(null);

const openDetials = (event: Event, row: IGetNotifiyMsgListItem) => {
  event.stopPropagation();
  visiblePopup.value = false;
  handleRead(row);
  openDetailsByNotifiyDataRef.value?.open(row);
};
</script>

<style lang="less" scoped>
:deep(.t-drawer__content-wrapper) {
  z-index: 99999999999 !important;
}
.header-msg {
  width: 400px;
  // height: 440px;
  margin: calc(0px - var(--td-comp-paddingTB-xs)) calc(0px - var(--td-comp-paddingLR-s));

  .empty-list {
    height: calc(100% - 120px);
    text-align: center;
    padding: 50px 0;
    font: var(--td-font-body-medium);
    color: var(--td-text-color-secondary);

    img {
      width: var(--td-comp-size-xxxxl);
    }

    p {
      margin-top: var(--td-comp-margin-xxl);
    }
  }

  &-top {
    position: relative;
    font: var(--td-font-title-medium);
    color: var(--td-text-color-primary);
    text-align: left;
    padding: var(--td-comp-paddingTB-l) var(--td-comp-paddingLR-xl) 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .clear-btn {
      right: calc(var(--td-comp-paddingTB-l) - var(--td-comp-paddingLR-xl));
    }
  }

  &-bottom {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: var(--td-comp-paddingTB-s) var(--td-comp-paddingLR-s);
    border-top: 1px solid var(--td-component-stroke);

    &-link {
      text-decoration: none;
      cursor: pointer;
      color: var(--td-text-color-placeholder);
    }
  }

  .t-list {
    height: 400px;
    padding: var(--td-comp-margin-s) var(--td-comp-margin-s);
  }

  .t-list-item {
    overflow: hidden;
    width: 100%;
    padding: var(--td-comp-paddingTB-l) var(--td-comp-paddingLR-l);
    border-radius: var(--td-radius-default);
    font: var(--td-font-body-medium);
    color: var(--td-text-color-primary);
    cursor: pointer;
    transition: background-color 0.2s linear;

    &:hover {
      background-color: var(--td-bg-color-container-hover);

      .msg-content {
        color: var(--td-brand-color);
      }

      .t-list-item__action {
        button {
          bottom: var(--td-comp-margin-l);
          opacity: 1;
        }
      }

      .msg-time {
        bottom: -6px;
        opacity: 0;
      }
    }

    .msg-content {
      margin-bottom: var(--td-comp-margin-s);
    }

    .msg-type {
      color: var(--td-text-color-secondary);
      width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .t-list-item__action {
      button {
        opacity: 0;
        position: absolute;
        right: var(--td-comp-margin-xxl);
        bottom: -6px;
      }
    }

    .msg-time {
      transition: all 0.2s ease;
      opacity: 1;
      position: absolute;
      right: var(--td-comp-margin-xxl);
      bottom: var(--td-comp-margin-l);
      color: var(--td-text-color-secondary);
    }
  }
}
</style>
