import { defineStore } from 'pinia';

import { getCosCredentials, GetDictionariesByCode } from '@/api/common';
import { store } from '@/store';

export const useCommonStore = defineStore('common', {
  state: () => ({
    stsCache: null,
    dictionaries: {},
  }),
  actions: {
    async requestGetCosCredentials() {
      const res = await getCosCredentials();
      this.stsCache = res.data;
      return res.data;
    },
    async requestGetDictionariesByCode(code: string[]) {
      const result = await GetDictionariesByCode(code);
      const addDictionaries = result.data.reduce((acc, cur) => {
        return { ...acc, [cur.dictCode]: cur.dictValues };
      }, {});
      this.dictionaries = { ...this.dictionaries, ...addDictionaries };
    },
  },
  persist: {
    paths: ['stsCache'],
  },
});

export function getCommonStore() {
  return useCommonStore(store);
}
