<template>
  <teleport to="body">
    <t-dialog v-model:visible="visible" :cancel-btn="() => null" width="600" @confirm="onClickConfirm">
      <template #header>
        <div>
          <p class="text-20px">{{ data.notifyName }}</p>
          <p class="text-14px c-#000000/40% mt-8px font-400">{{ data.createTime }}</p>
        </div>
      </template>
      <div>
        {{ data.content }}
      </div>
    </t-dialog>
  </teleport>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import type { IGetNotifiyMsgListItem } from '@/api/model/globalNotificationModel';

const visible = ref(false);
const data = ref({} as IGetNotifiyMsgListItem);
const onClickConfirm = () => {
  visible.value = false;
};

const open = (propsData: IGetNotifiyMsgListItem) => {
  data.value = propsData;
  visible.value = true;
};

defineExpose({
  open,
});
</script>

<style lang="less" scoped></style>
