import 'uno.css';
import 'tdesign-vue-next/es/style/index.css';
import '@/style/index.less';
import './permission';

import { watchWebsiteUpate } from '@laicui/utils';
import TDesign from 'tdesign-vue-next';
import { createApp } from 'vue';

import App from './App.vue';
import { setGlobalDirecitive } from './directive';
import router from './router';
import { store } from './store';

const app = createApp(App);

app.use(TDesign);
app.use(store);
setGlobalDirecitive(app);
watchWebsiteUpate();
app.use(router);
app.config.errorHandler = (err, instance, info) => {
  console.group('监听app错误信息');
  console.log(err, instance, info);
  console.groupEnd();
};
app.mount('#app');
