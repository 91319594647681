import { LaiFile } from '@laicui/types';
import { replace } from 'lodash';

import type {
  ISubmitSuppliersTransferRequest,
  ISuppliersChangeInfo,
  ISuppliersTransferInfo,
  LoginByPhoneParams,
  updateSuppliersMainInfoRequest,
} from '@/api/model/userModel';
import { request } from '@/utils/request';

import { BaseResponse } from './model/common';

const Api = {
  LoginByPhone: '/non-auth/user/phone/login',
  SendSmsForLogin: '/non-auth/sms/send/login/:phone',
  UserInfo: '/api/user/info',
  SuppliersInboundInfo: '/api/suppliers/manage/inbound/info',
  SuppliersInboundApply: '/api/suppliers/manage/inbound/apply',
  GetSuppliersInfo: '/api/suppliers/manage/info',
  SuppliersContactInfo: '/api/suppliers/manage/edit',
  Downloademplacement: '/api/suppliers/manage/down/emplacement',
  CloseLoginSSE: '/non-auth/sse/close',
  GetSuppliersChangeInfo: '/api/suppliers/manage/change/info',
  UpdateSuppliersMainInfo: '/api/suppliers/manage/change/edit',
  RevocationSuppliersChangeInfo: '/api/suppliers/manage/change/revocation',
  GetTransferIinfo: '/api/suppliers/manage/transfer/info',
  SubmitSuppliersTransfer: '/api/suppliers/manage/transfer/edit',
  RevocationSuppliersTransfer: '/api/suppliers/manage/transfer/revocation',
  DownloadTransferDoc: '/api/suppliers/manage/down/transfer',
};

export function loginByPhone(params: LoginByPhoneParams) {
  return request.post({
    url: Api.LoginByPhone,
    data: params,
  });
}

export function sendSmsForLogin(phone: string) {
  return request.post({
    url: replace(Api.SendSmsForLogin, ':phone', phone),
  });
}

export function getUserInfo() {
  return request.get({
    url: Api.UserInfo,
  });
}

export function saveSuppliersInboundInfo(data) {
  return request.post({
    url: Api.SuppliersInboundInfo,
    data,
  });
}

export function getSuppliersInboundInfo() {
  return request.get({
    url: Api.SuppliersInboundInfo,
  });
}

export function suppliersInboundApply(data) {
  return request.put({
    url: Api.SuppliersInboundApply,
    data,
  });
}

export function getSuppliersInfo() {
  return request.get({
    url: Api.GetSuppliersInfo,
  });
}

export function suppliersContactInfo(data) {
  return request.post({
    url: Api.SuppliersContactInfo,
    data,
  });
}

export function downloademplacement() {
  return request.get({
    url: Api.Downloademplacement,
  });
}

// 通知后端关闭登录的sse连接
export function closeLoginSSE(clientId: string) {
  return request.get({
    url: `${Api.CloseLoginSSE}/${clientId}`,
  });
}

// 获取供应商主体变更审核信息
export function getSuppliersChangeInfo() {
  return request.get<BaseResponse<ISuppliersChangeInfo>>({
    url: Api.GetSuppliersChangeInfo,
  });
}
// 供应商主体变更申请提交
export function updateSuppliersMainInfo(data: updateSuppliersMainInfoRequest) {
  return request.post({
    url: Api.UpdateSuppliersMainInfo,
    data,
  });
}

// 撤销主体变更申请
export function cancelSuppliersChangeInfo(id: string) {
  return request.delete<BaseResponse>({
    url: Api.RevocationSuppliersChangeInfo,
    params: `/${id}`,
  });
}

// 获取供应商过户信息
export function getSuppliersTransferInfo() {
  return request.get<BaseResponse<ISuppliersTransferInfo>>({
    url: Api.GetTransferIinfo,
  });
}

// 主体过户申请提交
export function submitSuppliersTransferInfo(data: ISubmitSuppliersTransferRequest) {
  return request.post<BaseResponse>({
    url: Api.SubmitSuppliersTransfer,
    data,
  });
}

// 撤销主体过户申请
export function cancelSuppliersTransfer(id: string) {
  return request.delete<BaseResponse>({
    url: Api.RevocationSuppliersTransfer,
    params: `/${id}`,
  });
}
// 下载主体过户申请书
export function downloadTransferDoc() {
  return request.get<
    BaseResponse<{
      fileJson: LaiFile[];
      transferAgreement: string;
      agreementUpdateTime: string;
    }>
  >({
    url: Api.DownloadTransferDoc,
  });
}
