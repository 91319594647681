import { request } from '@/utils/request';

import type { BaseResponse } from '../model/common';
import type {
  GetNotifiyMsgListResponse,
  GetUnreadMessageListResponse,
  IGetNotifiyMsgListRequest,
  ISubjectType,
} from '../model/globalNotificationModel';

enum API {
  GetGlobalUnreadNum = '/srm/msg/unread/num',
  GetUnreadMessageList = '/srm/msg/unread/list',
  SetMsgReadById = '/srm/msg/remark/read',
  BatchsetMsgReadByIds = '/srm/msg/remark/read/batch',
  GetNotifiyMsgList = '/srm/msg/search',
  AllMarkRead = '/srm/msg/remark/read/subject/all',
  DeleteMsgById = '/srm/msg/remove',
}

export const getGlobalUnreadNum = () => {
  return request.get<
    BaseResponse<{
      unreadNum: number;
    }>
  >({
    url: API.GetGlobalUnreadNum,
  });
};

// 获取未读消息列表
export const getUnreadMessageList = () => {
  return request.get<GetUnreadMessageListResponse>({
    url: API.GetUnreadMessageList,
  });
};

// 设置通知消息已读
export const setMsgReadById = (msgId: string) => {
  return request.post<BaseResponse>({
    url: `${API.SetMsgReadById}/${msgId}`,
  });
};

// 获取通知消息列表
export const GetNotifiyMsgList = (params: IGetNotifiyMsgListRequest) => {
  return request.get<GetNotifiyMsgListResponse>({
    url: API.GetNotifiyMsgList,
    params,
  });
};

// 根据id批量标记为已读
export const batchsetMsgReadByIds = (msgIds: string[]) => {
  return request.post<BaseResponse>({
    url: API.BatchsetMsgReadByIds,
    data: { msgIds },
  });
};

// 根据业务类型全部标记为已读
export const allMarkRead = (data: { subjectType: keyof ISubjectType | 'all' }) => {
  return request.post<BaseResponse>({
    url: API.AllMarkRead,
    data,
  });
};

// 根据msgId删除一条通知消息
export const deleteMsgById = (msgId: string) => {
  return request.post<BaseResponse>({
    url: `${API.DeleteMsgById}/${msgId}`,
  });
};
